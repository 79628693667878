import { gsap, TimelineLite, Expo, Bounce, Elastic } from 'gsap'
const timeline = new TimelineLite()

gsap.registerPlugin(TimelineLite)
gsap.registerPlugin(Expo)
gsap.registerPlugin(Bounce)
gsap.registerPlugin(Elastic)

import TypingAnimation from './TypingAnimation'; // Replace with the correct path to your modified class file

export default {
  data() {
    return {
      animation: timeline,
      tweenExpo: Expo,
      tweenBounce: Bounce,
      tweenElastic: Elastic,
      parseEase: gsap.parseEase,
      TypingAnimation: new TypingAnimation(),
    }
  },
  methods: {
    /**
     * Spin and fade animation
     * @param object
     * @returns {Promise<void>}
     */
    async spinAndFade(object) {
      await this.animation
          .to(object, 0.4, { rotation: "+=180", scale: 2, autoAlpha: 0, transformOrigin: "50% 50%", ease: "expo.inOut" });
    },
    /**
     * Reverse spin and fade in animation
     * @param object
     * @returns {Promise<void>}
     */
    async reverseSpinAndFade(object) {
      await this.animation
          .to(object, 0.4, { rotation: "-=180", scale: 1, autoAlpha: 1, transformOrigin: "50% 50%", ease: "expo.inOut" });
    },
    /**
     * Animates the game container to the left. Resets the y position to 0 before animating
     * @returns {Promise<void>}
     */
    async animateBoardSwipeLeft() {
      this.animation.set(this.container, {y: 0});  // Set y to 0 immediately, without animation
      await this.animation.to(this.container, 1.8, {x: -3000, ease: this.tweenExpo.easeOut});
    },
    /**
     * Animates the game container to the bottom. Resets the y position to 3000 before animating to make sure we don't have any weirdness
     * @returns {Promise<void>}
     */
    async animateBoardSwipeDown() {
      // use isi_min/isi_max
      await this.animation.to(this.container, 1.8, {y: 3000, ease: this.tweenExpo.easeOut});
    },
    /**
     * Animates the game container to the right. Resets the y position to 3000 before animating
     * @returns {Promise<void>}
     */
    async animateBoardSwipeRight() {
      this.animation.set(this.container, {y: 0});  // Set y to 0 immediately, without animation
      await this.animation.to(this.container, 1.8, {x: 3000, ease: this.tweenExpo.easeOut});
    },
    /**
     * Animates the game container to to the middle of board. Resets the x position to 3000 before animating
     * @returns {Promise<void>}
     */
    async animateBoardSwipeIn() {
      this.animation.set(this.container, {y: 0, x: -3000});  // Set y to 0 immediately, without animation
      await this.animation.to(this.container, 1.8, {x: 0, ease: this.tweenExpo.easeOut});
    },

  },
  computed: {

  }
}
