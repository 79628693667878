//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../shared/GlobalMixins.js'
import gamePlayMixin from "../../../shared/GamePlayMixin"

export default {
    name: 'Instructions',
    mixins: [GlobalMixin, gamePlayMixin],
    data () {
        return {
            instructionIndex: 0,
            showInstructions: false,
            activeText: {
                'title': '',
                'body': '',
                'buttonText': '',
                'buttonText2': '',
                'buttonAction': '',
                'buttonAction2': ''
            },
            activeTitle: '',
            activeBody: '',
            activeButtonText: '',
            action: 'goToNext'
        }
    },
    methods: {
        goToNext() {
            this.showInstructions = false
            this.instructionIndex++
            if (this.instructionIndex <= this.getHighestInstructionIndex()) {
                this.activeText = this.mainInstructions[this.instructionIndex]
            }

            this.showInstructions = true
        },
        goToBeginning() {
            this.showInstructions = false
            this.instructionIndex = 0
            this.activeText = this.mainInstructions[this.instructionIndex]

            this.showInstructions = true
        },
        goToPlay() {
            this.setCurrentStep('play');
        },
        goToStep(step) {
            this[step]()
        },
        getHighestInstructionIndex() {
            return this.mainInstructions.indexOf(this.mainInstructions[this.mainInstructions.length-1])
        }
    },
    mounted() {
        this.activeText = this.mainInstructions[this.instructionIndex]
        this.showInstructions = true
        console.log('value', this.mainInstructions)
    },
    computed: {
        isLastInstruction() {
            return this.instructionIndex === this.mainInstructions.indexOf(this.mainInstructions[this.mainInstructions.length-1])
        }
    }
}
