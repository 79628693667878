import GlobalMixin from '../shared/GlobalMixins.js';

export default {
    mixins: [GlobalMixin],
    data() {
        return {};
    },
    methods: {
        initializeGameStats() {
            this.setGameExited(false);
            this.setCurrentRound(1);
            this.setCurrentTrial(1);
            this.setScore(0);
            this.setHints([]);
            this.setTextPlayHints('');
            this.modalMessageReset();
            this.container = this.$refs.gameplay;
            this.incorrectAnswers = false;
            this.playDisabled = true;
            this.setTimer('false');
            this.setPlayId(false);
            this.clearTrialStatus();
        }
    },
    mounted() {
        this.initializeGameStats();
    }
};
