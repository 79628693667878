//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../shared/GlobalMixins.js'
import GamePlayMixin from '../../../shared/GamePlayMixin.js'


export default {
  name: 'Introduction',
  mixins: [GlobalMixin, GamePlayMixin],
  data () {
    return {
      splash: false
    }
  },
  created () {
    this.setMusic(this.assets.audio.theme)
  }
}
