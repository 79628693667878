import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/ScoutTrainer/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlayMusic: 'gameplay.mp3',
    incorrect: 'effect-wrong-button.mp3',
    correct: 'effect-correct.mp3',
    correctHint: 'effect-correct-hint.mp3',
    roundSuccess: 'effect-success-round.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    army1: 'army1.webp',
    army2: 'army2.webp',
    army3: 'army3.webp',
    army4: 'army4.webp',
    tile1: 'tile-overlay1.webp',
    tile2: 'tile-overlay2.webp',
    tile3: 'tile-overlay3.webp',
    modal: 'modal.webp',
    orderBonus: 'order-bonus.svg',
  }
};

export default generateAssets(assetsDir, gameAssets);