export default class TypingAnimation {
    constructor() {
        this.typeValue = '';
        this.code = '';
        this.targetProperty = '';
    }

    targetValue(targetValue) {
        this.targetProperty = targetValue;
    }

    typeEffect(code) {
        this.code = code;
        this.typeNextCharacter();
    }

    typeNextCharacter() {
        if (this.typeValue.length < this.code.length) {
            const currentChar = this.code.charAt(this.typeValue.length);
            const isSpaceOrPunctuation = /\s|\W/.test(currentChar); // Check if current character is a space or punctuation
            const delay = isSpaceOrPunctuation ? 50 : Math.random() * 50; // Longer delay for spaces or punctuation, random delay for other characters
            setTimeout(() => {
                this.typeValue += currentChar;
                this.targetProperty = this.typeValue
                requestAnimationFrame(() => this.typeNextCharacter());
            }, delay);
        }
    }
}
