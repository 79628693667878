export default {
  gamePlay: {
    playTitle: "Now find them, scout!",
    playInstructions: "<p>Click on the positions of each of the largest armies you found.</p>",
    playButton: "Let's go!",
    instructions: {
      find: "Find the largest army, scout!",
      findInOrder: "Find the largest army in correct order!",
      correct: "Correct!",
      correctBonus: "Correct, order bonus!",
      incorrect: "Not the one, scout!"
    }
  },
  rounds: {
    end: "End of Round {{round}}",
    playNext: "Play next round!",
    all: "Excellent! You got {{correct}} trials correct!",
    some: "Great job! You got {{correct}} trials correct in this round.",
    none: "Good try. Let's keep practicing!"
  },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
  gameComplete: {
    playTitle: "Game Completed!",
    playInstructions: "Great work, you got {{correct}} trials in this round and completed the game for the day. Keep practicing!",
    playButton: 'Return to games'
  },
    instructions: [
        {
            'title': 'We\'re Under Attack!',
            'body': 'You\'re not a moment too soon!<br>\n' +
                'The ORC ARMY is attacking and only you can stop them!',
            'buttonText': 'Got it.',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Identify Targets!',
            'body': 'FIRST, find the LARGEST orc army in each wave.<br>\n' +
                'Click them to mark their spot!',
            'buttonText': 'Got it.',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Locate and Banish!',
            'body': 'REMEMBER where you saw the largest army in each wave.<br>'+
            'To win, click their spots, IN the ORDER you scouted them!',
            'buttonText': 'Got it.',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
}
