//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';


export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      activeButtons: [],
      advanceToNextGamePhase: false,
      buttonReaction: null,
      buttonReactionClass: null,
      // we need to make sure all indexes are initialized to empty strings
      buttonStates: Array(18).fill().map(() => Array(3).fill("")),
      buttons: [],
      buttonsLength: 18,
      buttonsForAnswerReveal: [],
      gamePlayCopy: gamePlayCopy,
      clickedAnswers: [],
      clickedAnswersTrial: [],
      container: null,
      correctAnswersThisRound: 0,
      targetArmiesThisRound: 0,
      clickedIndexThisTrial: [],
      correctThisRound: 0,
      currentStim: 1,
      displayArmies: [],
      endRoundText: '',
      expectedAnswers: [],
      hintMode: true,
      hintedButton: [],
      incorrectAnswersThisRound: false,
      incorrectAnswersThisTrial: false,
      nextTrial: false,
      playDisabled: false,
      playInstruction: '',
      playInstructionQueue: [],
      playStart: null,
      previousCorrectArmyCoords: new Set(),
      showClickedArmy: null,
      stimPerTrial: 1,
      totalAnswersPerRound: 0,
      totalAnswersPerTrial: 1,
      trialCorrectAnswers: [],
      showOrderBonus: false,
      wrongOrder: false,
      wasAnswerClicked: false,
      scoring: {
        correctArmy: 50,
        incorrectArmy: 20,
        correctResp: 100,
        incorrectResp: 50,
        orderBonus: 50,
        hintCorrect: 50
      },
      randomNumbers: {
        armyAmount: null,
        numberOfArmies: null,
        correctArmyIndex: null,
        armyXCoordinate: null,
        armyYCoordinate: null
      }
    };
  },
  methods: {
    /**
     * Initialize the game play
     * @returns {Promise<void>}
     */
    async initializeGamePlay() {
      // don't let them play until we get past the instruction modal
      this.setGameExited(false);
      this.setTimer(false);

      this.container = this.$refs.gameplay;
      this.playDisabled = true;

      this.setScoreUpHint(this.scoring.hintCorrect);
      // reveal selected buttons for this session
      console.log('initializing gameplay');
      // loop for each round
      // get the user level, setup grid for first trial/round
      await this.getSettings(); // this will actually get the user, grid data

      // loop through each stim per trial
      await this.setupAndHintStim(this.currentStim);

      // set the play start time
      // show translate and respond dialog, pick last (or multiples, if level dictates)
      // we need to wait until they click dialog button before we advance to next stage
    },
    /**
     * Setup the buttons and hints for the game
     * @param stim
     * @returns {Promise<unknown>}
     */
    async setupAndHintStim (stim) {
      await this.setupButtons();
        // only do this stuff on the first stim
        if (stim === 1) {
          await this.animation.to(this.container, 1.8, {y: 0, ease: this.tweenExpo.easeOut});
          if (typeof this.assets.audio.gamePlayMusic !== 'undefined') {
            this.setMusic(this.assets.audio.gamePlayMusic);
          }
          this.playStart = new Date().getTime();
        }

        // this.startLoopedPlayback(this.assets.audio.theme) // start gameplay music
        await this.showGameplayHints();
        await this.sleep(1000);
    },
    /**
     * Get the game settings and set the level
     * @returns {Promise<unknown>}
     */
    async getSettings() {
      let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });
        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);
        this.setLevel(this.gameLevelData.start_level);
        this.previousCorrectArmyCoords = new Set();

        this.setRounds(5);
        this.setTrials(2);
        console.log('stim per trial', this.gameLevelData.game_settings.stim_per_trial)
        this.stimPerTrial = Number(this.gameLevelData.game_settings.stim_per_trial);
        this.currentStim = 1;
    },
    /**
     * Create empty buttons for the game grid
     * @returns {Promise<unknown>}
     */
    async initializeEmptyButtons() {
      let buttons = [];
      let evenRow = false;
      let tile = 1;

      for (let i = 0; i < this.buttonsLength; i++) {
        let rowPosition = Math.floor(i / 6);
        let colPosition = i % 6;

        if (i % 6 === 0) {
          evenRow = !evenRow;
          tile = evenRow ? 1 : 3;
        }

        buttons.push({
          index: i,
          tileBackground: 'tile' + tile,
          clicked: false,
          isActive: false,
          activeColor: false,
          correct: false,
          reaction: null,
          orcs: this.createOrcArmy(0),
          coords: {
            x: colPosition,
            y: rowPosition
          },
          positionIndex: this.calculatePositionIndex({x: colPosition, y: rowPosition})
        });

        tile = this.getTile(evenRow, tile);
        
      }

      return buttons;
    },
    getTile(evenRow, tile) {
      let newTile;
      if (evenRow) {
        newTile = (tile === 1) ? 2 : 1;
      } else {
        newTile = (tile === 3) ? 1 : 3;
      }
      return newTile;
    },
    /**
     * Create single orc army for the game grid
     * @param amount
     * @returns {array}
     */
    createOrcArmy(amount) {
      let army = [];

      for (let i = 0; i < amount; i++) {
        army.push('army' + this.displayArmies[Math.floor(Math.random() * this.displayArmies.length)]);
      }

      if (amount < 9) {
        let fillerSpaceCount = 9 - amount;
        for (let i = 0; i < fillerSpaceCount; i++) {
          army.push('filler');
        }
      }

      return this.shuffleArray(army);
    },
    calculatePositionIndex(coords) {
      const gridWidth = 6; // Since you have six columns
      return coords.y * gridWidth + coords.x; // Calculate linear index
    },
    /**
     * Build the armies for the game grid
     * @param numberOfArmies
     * @param allCoordinates
     * @param correctArmyIndex
     * @param armies
     * @returns {Promise<unknown>}
     */
    async buildArmies(numberOfArmies, allCoordinates, correctArmyIndex, armies) {
      console.table({
        numberOfArmies: numberOfArmies,
        allCoordinates: allCoordinates,
        correctArmyIndex: correctArmyIndex,
        armies: armies
      });
      const range = this.range(0, numberOfArmies - 1);

      for (const index of range) {
        let army = {
          coords: allCoordinates[index]
        };

        const isCorrectArmy = index === correctArmyIndex;
        const minMaxKey = isCorrectArmy ? 'correct_army' : 'other_armies';

        army.amount = await this.randomNumbers.armyAmount
            .setMinMax(
                this.gameLevelData.game_settings[`${minMaxKey}_low`],
                this.gameLevelData.game_settings[`${minMaxKey}_high`]
            ).getRandomIntegerInRange();

        army.orcs = this.createOrcArmy(army.amount); // Assuming this is an async function

        army.correct = isCorrectArmy;
        army.config = [[0, 0, 0], [0, 0, 0], [0, 0, 0]];

        armies.push(army);
      }

      return armies;
    },
    /**
     * Bring it all together and build the whole play grid
     * @returns {Promise<unknown>}
     */
    async setupButtons() {
      console.log('setupButtons');
      this.clearCorrectAnswers();
        this.activeButtons = [];
        const settings = this.gameLevelData.game_settings;

        this.clearAllReactions();
        // shuffle colors
        this.buttons = await this.initializeEmptyButtons();
        console.log('buttons', this.buttons);
        let numberOfArmies = await this.randomNumbers.numberOfArmies
                .setMinMax(settings.total_armies_low,
                    settings.total_armies_high).getRandomIntegerInRange(),
            armies = [],
            correctArmyIndex = 0,
            allCoordinates = [],
            correctArmies = [],
            correctArmy = {};

        // create a list of all coordinates
        this.range(0, 5).forEach((x) => { // columns
          this.range(0, 2).forEach((y) => { // rows
            allCoordinates.push({x: x, y: y});
          });
        });

        let currentCorrectCoords;
        let currentCorrectCoordsStr;

        allCoordinates = this.shuffleArray(allCoordinates);
        do {
          correctArmyIndex = await this.randomNumbers.correctArmyIndex
              .setMinMax(0, numberOfArmies - 1).getRandomIntegerInRange();
          currentCorrectCoords = allCoordinates[correctArmyIndex];
          currentCorrectCoordsStr = JSON.stringify(currentCorrectCoords);
        } while (currentCorrectCoords && this.previousCorrectArmyCoords.has(currentCorrectCoordsStr));

        this.previousCorrectArmyCoords.add(currentCorrectCoordsStr);

        allCoordinates = allCoordinates.slice(0, numberOfArmies);

        this.displayArmies = this.gameLevelData.game_settings.orc_display.split(",").map(Number);

        armies = await this.buildArmies(numberOfArmies, allCoordinates, correctArmyIndex, armies);

        correctArmy = armies[correctArmyIndex];
        correctArmy.positionIndex = this.calculatePositionIndex(correctArmy.coords);
        correctArmies.push(armies[correctArmyIndex]);

        //check to make sure correct is larger then the other armies and create configuration
        armies.forEach((a, index) => {
          if (index !== correctArmyIndex && a.amount >= correctArmy.amount) {
            if (correctArmy.amount === 1) {
              correctArmy.amount = 2;
            } else {
              correctArmy.amount -= 1;
            }
          }
        });

        for (const a of armies) {
          // create a configuration based on parameters
          for (let i = 0; i < a.amount; i++) {
            let coord;
            do {
              coord = {
                x: await this.randomNumbers.armyXCoordinate
                    .setMinMax(0, 2).getRandomIntegerInRange(),
                y: await this.randomNumbers.armyYCoordinate
                    .setMinMax(0, 2).getRandomIntegerInRange()
              };
            } while (a.config[coord.x][coord.y] !== 0);

            a.config[coord.x][coord.y] = this.getSample(this.gameLevelData.game_settings.orc_display);
          }

          let armyIndex = this.calculatePositionIndex(a.coords);
          this.buttons[armyIndex].isActive = true;
          this.buttons[armyIndex].positionIndex = armyIndex;
          this.buttons[armyIndex] = {...this.buttons[armyIndex], ...a};
          this.activeButtons.push(this.buttons[armyIndex]);
        }


        correctArmies.forEach((a) => {
          // get index from coordinates
          let armyIndex = this.calculatePositionIndex(a.coords);
          // graft data for that army onto the position
          this.buttonsForAnswerReveal[armyIndex] = this.buttons[armyIndex];
        });

        this.totalAnswersPerTrial = correctArmies.length;
        this.setHints([]);

        this.expectedAnswers.push(correctArmy);
        this.trialCorrectAnswers.push(correctArmy);

        // for each button in this.buttons, we need to get the orcs values and set them in this.buttonState for each button, with a corresponding index for each button with the orcs values as an array
        this.buttons.forEach((button, index) => {
          this.buttonStates[index] = button.orcs;
        });

        if (this.activeButtons.length > 0 && this.buttons.length > 0) {
          return true;
        }

        this.targetArmiesThisRound += correctArmies.length;
        // keep track of correct army index so we don't repeat it in the same trial
        // this.previousCorrectArmyCoords.push(currentCorrectCoords);

        throw new Error('No active buttons were set for this round.');
    },
    /**G
     * Calculate the timer duration and start the timer
     * @returns {Promise<void>}
     */
    async calculateAndStartTimer () {
      let is_phase_1 = false;

      let timerDuration = (is_phase_1 ? this.gameLevelData.game_settings.timer_duration_1 : this.gameLevelData.game_settings.timer_duration_2) * 1000;

      this.setTimerStopAfter(timerDuration);
      await this.startTimer(Math.floor(this.timerStopAfter / 100), true);

    },
    /**
     * Start game play mode
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      console.log('startGamePlayMode')
      if (!this.advanceToNextGamePhase) {
        this.playDisabled = false;
        this.resetModalMessage();
        this.hintMode = false;
        await this.hideButtonSequence();
        this.playInstruction = gamePlayCopy.gamePlay.instructions.findInOrder;
        // really all that should happen here is that we start the timer. we should also
        // react to timer being done
        this.trialStart = new Date().getTime();
      }
    },
    /**
     * Show the button sequence
     * @returns {Promise<void>}
     */
    async showButtonSequence() {
      let orcs = document.querySelectorAll('.orc:not(.filler)');
        let randomOrcKeys = this.shuffleArray([...Array(orcs.length).keys()]);

        for (let i = 0; i < orcs.length; i++) {
          let orc = orcs[randomOrcKeys[i]];
          if (this.gameExited) {
            this.unloadMusic();
            resolve(true);
            break;
          }

          orc.classList.add('shown');
          await this.sleep(this.getRandomIntegerInRange(10, 40));
        }

        this.hintedButton = [];
    },
    /**
     * Hide the button sequence
     * @returns {Promise<void>}
     */
    async hideButtonSequence() {
      for (let orc of document.getElementsByClassName('orc')) {
          if (this.gameExited) {
            resolve(true);
            break;
          }

          orc.classList.add('faded');
        }

        this.hintedButton = [];
        await this.sleep(500);
    },
    /**
     * Show the gameplay hints
     * @param count
     * @returns {Promise<void>}
     */
    async showGameplayHints(count = 1) {
      console.log('start showing button hints');
      let handleNextStep = false;
      // show hints dependent on Level
      this.playDisabled = true;

      this.playInstruction = gamePlayCopy.gamePlay.instructions.find;
      this.playDisabled = false;
      await this.showButtonSequence();
      this.hintMode = true;
      if (this.gameExited) return; // make sure if the user exits at this stage, we don't keep going

      await this.calculateAndStartTimer();
      await this.sleep(800); // stop for 800ms so it doesn't feel so rushed
      if (!this.wasAnswerClicked) {
        this.playInstruction = gamePlayCopy.gamePlay.instructions.incorrect;

        this.decreaseScore(this.scoring.incorrectArmy);

        this.setEffect(this.assets.audio.incorrect);
        console.log('∞∞∞∞ answer was not clicked in time')

        if (Object.keys(this.modalMessage).length === 0) {
          await this.handleAnswerNextStep();
          handleNextStep = true;
        }
      }

      this.clickedAnswers.push(false);
      this.clickedAnswersTrial.push(false);

      this.wasAnswerClicked = true;
      this.clearTimerInterval();

      // avoid going to next step if the modal is up
      if (Object.keys(this.modalMessage).length === 0 && !handleNextStep) {
        await this.sleep(1800);
        await this.handleAnswerNextStep();
      }
    },
    /**
     * Get the button image for the given orc
     * @param orc
     * @returns {string}
     */
    getButtonState(orc) {
      return this.assets.images[orc];
    },
    /**
     * Set the button reaction
     * @param button
     * @param reaction
     * @param sleep
     * @returns {Promise<void>}
     */
    async setButtonReaction(button, reaction, sleep = 3000) {
      this.$set(this.buttons[button], 'reaction', reaction);
      if (button === 0) {
        console.log('button 0 active', this.buttons[0]);
      }
      await this.sleep(sleep);

      this.$set(this.buttons[button], 'reaction', null);
    },
    async handleHintMode(clickedButton) {
      if (clickedButton.correct) {
        console.log('clicked during hint mode');
        this.setEffect(this.assets.audio.correctHint);
        this.playInstruction = gamePlayCopy.gamePlay.instructions.correct;

        // increase their score

        this.setTimerBonus(this.timer);
        this.increaseScore(this.scoring.correctArmy + this.timerBonus);
      } else {
        this.playInstruction = gamePlayCopy.gamePlay.instructions.incorrect;

        // decrease their score
        this.decreaseScore(this.scoring.incorrectArmy);
      }

      await this.sleep(1000);
      await this.hideButtonSequence();
      this.playInstruction = '';

      // if currentStim is less that totalStims then we go to the next stim

      if (this.currentStim < this.stimPerTrial) {
        this.currentStim++;
        console.log('setupAndHintStim');
        await this.setupAndHintStim(this.currentStim);
      } else {
        // if we've hit the total amount of stimuli we start gameplay mode
        this.clearTimerInterval();
        this.playInstruction = '';
        this.clickedIndexThisTrial = [];
        this.setModalMessage({
          title: gamePlayCopy.gamePlay.playTitle,
          body: this.formatText(gamePlayCopy.gamePlay.playInstructions, {}),
          actionButton: {action: this.startGamePlayMode, text: gamePlayCopy.gamePlay.playButton}
        });
      }
    },
    async processClickAnswer(clickedButton, button, orcArmy) {
      this.playDisabled = true;
      this.wasAnswerClicked = true;
      let isCorrect = this.expectedAnswers.find(
          (obj) => obj.positionIndex === clickedButton.positionIndex
      );
      this.clickedAnswers.push(isCorrect);
      this.clickedAnswersTrial.push(isCorrect);

      if (!isCorrect) {
        await this.handleIncorrectAnswer(button);
      } else {
        await this.handleCorrectAnswer(clickedButton, orcArmy, button);
      }
    },
    async handleIncorrectAnswer(button) {
      this.playInstruction = gamePlayCopy.gamePlay.instructions.incorrect;
      this.decreaseScore(this.scoring.incorrectArmy);

      this.setEffect(this.assets.audio.incorrect);
      await this.setButtonReaction(button, "incorrect", 1800);
      this.incorrectAnswersThisRound = true;
      this.incorrectAnswersThisTrial = true;

    },
    async handleCorrectAnswer(clickedButton, orcArmy, button) {
      console.log(this.expectedAnswers, 'expected answers')
      let expectedAnswer = this.expectedAnswers.shift();

      this.buttons = [...this.buttonsForAnswerReveal];

      this.setEffect(this.assets.audio.correct);


      this.showClickedArmy = button;
      this.setTimerBonus(this.timer);

      console.table({
        'clicked answer': clickedButton.positionIndex, 'expected' : expectedAnswer.positionIndex
      })

      if (
          clickedButton.positionIndex !== expectedAnswer.positionIndex &&
          this.expectedAnswers.length > 1
      ) {
        console.log('not in order')
        this.playInstruction = gamePlayCopy.gamePlay.instructions.findInOrder;

        this.increaseScore(this.scoring.correctResp+this.timer);
      } else {
        console.log('in order, bonus')
        this.increaseScore(this.scoring.correctResp + this.scoring.orderBonus+this.timer);
        this.playInstruction = gamePlayCopy.gamePlay.instructions.correctBonus;
        this.showOrderBonus = true;
        await this.sleep(1000);
        this.showOrderBonus = false;
      }

      console.log('button 0', this.buttons[0]);

      await this.setButtonReaction(clickedButton.positionIndex, "correct", 1800);
      this.addToCorrectAnswers(clickedButton);
      this.correctThisRound = this.correctThisRound + 1;
      this.playDisabled = false;
    },
    async handleAnswerNextStep (orcArmy=false) {
      // if there are remaining expected answers, show them the instruction again and let them try to choose.
      // at this point their answer was already marked as incorrect, but we let them choose the remaining item anyways
      if (!this.exhaustedAnswersForTrial) {
        console.log('•••• not exhausted answers for trial')
        this.advanceToNextGamePhase = false;
        this.clearAllReactions();
        this.playDisabled = false;

        this.playInstruction = gamePlayCopy.gamePlay.instructions.findInOrder;

        await this.calculateAndStartTimer();

      } else {
        console.log('•••• exhausted answers for trial')
        this.advanceToNextGamePhase = true;
        await this.sleep(1000);
        // no more expected answers so we go to next trial/etc
        this.playDisabled = true;

        if (orcArmy) {
          for (let orc of orcArmy.getElementsByClassName('orc')) {
            if (this.gameExited) {
              break;
            }

            this.classList(orc).add('faded').remove('dead');
          }
        }

        console.log('go to next trial');
        if (!this.incorrectAnswersThisTrial) this.updateTrialsCorrect(this.trialsCorrect + 1);
        await this.determineNextGamePhase();
      }
    },
    /**
     * Handle the gameplay mode
     * @param button
     * @param clickedButton
     * @returns {Promise<void>}
     */
    async handleGamePlayMode(button, clickedButton) {
      // we're in live play mode, not hint mode
      // set the buttons to be just the correct answers so we can show them
      this.setStopTimer(true);
      try {
        this.trialEnd = new Date().getTime();
        this.trialTime = this.trialEnd - this.trialStart;
      } catch (e) {
        console.log(e);
      }

      let orcArmy = this.$refs[`army${button}`][0];
      let trialAnswerArmy = this.trialCorrectAnswers.find(obj => obj.positionIndex === button);

      if (trialAnswerArmy !== undefined) this.buttons[button] = trialAnswerArmy;

      if (!this.playDisabled) {
        await this.processClickAnswer(clickedButton, button, orcArmy);
      }


      this.playInstruction = '';

      await this.handleAnswerNextStep(orcArmy);
    },
    /**
     * Evaluate the user's click to see if it was correct
     * @param button
     * @returns {Promise<>}
     */
    async evaluateClick(button) {
      console.log('evaluateClick')
      if (!this.clickRateLimit('grid'+button)) {
        return false;
      }

      console.log('clickRateLimit didnt fire', button);

      if (this.playDisabled) {
        console.log('play is disabled')
        return false;
      }

      if (this.clickedIndexThisTrial.includes(button)) {
        console.log('already clicked this trial')
        return false;
      }

      this.clickedIndexThisTrial.push(button);

      let clickedButton = this.buttons[button];
      this.clearTimerInterval();
      if (this.hintMode) {
        this.playDisabled = true;
        return await this.handleHintMode(clickedButton);
      } else {
        await this.handleGamePlayMode(button, clickedButton);
      }
    },
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    async handleGamePlayComplete() {
      await this.endRoundScore();
      this.increaseTickets(6);
      this.setTicketEffect();
      // all of the answers are correct
      // game play is complete. confetti or whatever
      this.playDisabled = true;
      console.log('gameplay is complete', this.gameId, this.play_id, this.level);
      await this.updatePlayLevel({
        gameId: this.gameId, play_id: this.play_id, level: Math.max(1, this.level), tickets: this.tickets, stopTime: new Date().getTime()
      });
      // all of the answers are correct
      // game play is complete. confetti or whatever
      if (this.movedUpALevel) {
        this.showMovedUpALevelModal();
      } else {
        this.showEndOfGameModal();
      }
    },
    /**
     * Determine if we're going to the next trial or the next round
     * @returns {Promise<void>}
     */
    async determineNextGamePhase() {

      if (!this.incorrectAnswersThisTrial) {
        this.setTrialStatus('correct');
        this.trialWasCorrect = true;
      } else {
        this.setTrialStatus('incorrect');
        this.trialWasCorrect = false;
      }

      await this.setGameLevelAndSaveTrialResults();

      this.totalAnswersPerRound += this.trialCorrectAnswers.length;
      this.trialCorrectAnswers = [];

      if (!this.gamePlayComplete) {
        console.log('gameplay not complete');
        console.log('current trial', this.currentTrial, 'total trials', this.trials);
        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        console.log('gameplay is complete');
        await this.handleGamePlayComplete();
      }
    },

    /**
     * Save the trial and create a new round if needed
     * @returns {Promise<void>}
     */
    async goToNextTrial() {
      console.log('go to next trial');
      this.resetModalMessage();
      this.clickedIndexThisTrial = [];
      this.previousCorrectArmyCoords = [];
      this.incorrectAnswersThisTrial = false;
      this.playDisabled = true;
      this.clickedAnswersTrial = [];
      this.wasAnswerClicked = false;
      // this lets the board rendering know to stop showing hints while we're switching to next phase
      this.advanceToNextGamePhase = false;
      this.startNewRound();
      await this.animation.to(this.container, 2.2, {y: -2000, ease: this.tweenExpo.easeOut});

      await this.getSettings();
      await this.setupButtons();

      this.setTimer(false);
      await this.animation.to(this.container, 1.4, {y: 0, ease: this.tweenExpo.easeOut});
      // is this the last trial for this round?

      if (this.currentTrial === 1) this.correctThisRound = 0; // reset this for new rounds
      this.playStart = new Date().getTime(); // start counting the next trial time
      await this.showGameplayHints();

    },
    /**
     * Calculate the score for the round and update the level
     * @returns {Promise<void>}
     */
    async endRoundScore() {
      // all of the answers are correct
      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');

        this.goUpLevel();
        this.increaseTickets(3);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      // all of the answers are wrong
      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');
        this.goDownLevel();
      }

      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
    },
    /**
     * Go to the next round
     * @returns {Promise<void>}
     */
    async goToNextRound() {
      if (this.allAnswersCorrect) {
        console.log('no incorrect answers, go to next round');
        this.endRoundText = gamePlayCopy.rounds.all;
      }

      if (this.someAnswersCorrect) {
        console.log('some incorrect answers, go to next round');
        this.endRoundText = gamePlayCopy.rounds.some;
      }

      if (this.noAnswersCorrect) {
        console.log('no correct answers, go to next round');
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      this.incorrectAnswersThisRound = false;
      if (!this.gamePlayComplete) {
        this.setModalMessage({
          title: this.formatText(
              gamePlayCopy.rounds.end,
              {round: this.currentRound},
              false
          ),
          body: this.formatText(this.endRoundText, {correct: this.trialsCorrect}),
          actionButton: {
            action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
            text: gamePlayCopy.rounds.playNext
          }
        });
      }

      await this.sleep(500);
      this.updateTrialsCorrect(0);
    },
    /**
     *
     * @returns {boolean}
     */
    showEndOfRoundModal() {
      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: this.correctThisRound}),
        actionButton: {
          action: this.goToNextTrial,
          text: gamePlayCopy.rounds.playNext
        }
      });

      return true;
    },
    /**
     * Clear all of the reactions from the buttons
     * @returns {void}
     */
    clearAllReactions() {
      for (const button of this.buttons) {
        button.reaction = null;
      }
    },
    /**
     * Generate the classes for each button in the grid
     * @param button
     * @returns {string}
     */
    buttonClasses(button) {
      let classes = button.tileBackground + ' ';
      classes += button.reaction === 'correct' ? 'correct ' : '';
      classes += button.reaction === 'incorrect' ? 'incorrect ' : '';
      classes += this.playDisabled ? 'play-disabled ' : '';
      classes += this.hintMode ? 'hint-mode ' : '';
      if (button.positionIndex === 0) {
        console.log('button classes', classes)
      }

      return classes;
    },
    /**
     * Check if the button is in the current trial's correct answers
     * @param id
     * @returns {boolean}
     */
    isCurrentButtonInTrialAnswers(id) {
      let active = this.trialCorrectAnswers.filter(obj => {
        return obj.positionIndex === id;
      });

      return active.length > 0;
    },
    /**
     * Check if the button is in the current active buttons
     * @param id
     * @returns {boolean}
     */
    isCurrentButtonInActive(id) {
      let active = this.activeButtons.filter(obj => {
        return obj.positionIndex === id;
      });

      return active.length > 0;
    }
  },
  computed: {
    getOrcClass() {
      return (button, otherClasses) => {
        if (this.showClickedArmy && this.showClickedArmy === button) {
          return ['orc', 'shown', 'dead'];
        } else {
          return otherClasses;
        }
      };
    },
    /**
     * Calculate the timer bonus
     * @returns {number}
     */
    timerBonus() {
      return (100 - (Math.floor(this.timer) / 100) * 10);
    },
    exhaustedAnswersForTrial() {
      return this.clickedAnswersTrial.length === this.stimPerTrial;
    },
    allAnswersCorrect() {
      return this.trialsCorrect === this.trials;
    },
    someAnswersCorrect() {
      return this.trialsCorrect < this.trials && this.trialsCorrect > 0;
    },
    noAnswersCorrect() {
      return this.trialsCorrect < 1;
    }
  },
  watch: {
    showClickedArmy(newVal) {
      if (newVal || newVal === 0) {
        // set all values in array this.buttonStates[newVal] to 'orc shown'
        this.buttonStates[newVal] = this.buttonStates[newVal].map(() => 'orc shown');
        setTimeout(() => {
          this.buttonStates[newVal] = this.buttonStates[newVal].map(() => 'orc shown dead');
        }, 150);
      }
    },
  },
  async created() {
    // initialize the random number generators
    Object.keys(this.randomNumbers).forEach(key => {
      this.randomNumbers[key] = this.createUniqueRandomGenerator();
    });
  },
  async mounted() {
    console.log('mounted')
    this.buttonsForAnswerReveal = await this.initializeEmptyButtons();
    this.TypingAnimation.targetValue(this.playInstruction);
    await this.initializeGamePlay(); // start round 1, trial 1
  },
};
